<template>
    <Header title="Refund and Return Policy" />
    <div class="top_margin"></div>
    <section class="row_padding">
        <main class="header-offset content-wrapper about-wrapper">
            <div class="terms-container">
                <div class="row">
                    <div class="col-sm-8 col-sm-offset-2">
                        <section class="terms-title">
                            <h1>Rio Hill</h1>
                        </section>
                        <section class="terms-title">
                            <h1>Refund and Return Policy</h1>
                        </section>

                        <div class="terms-body">

                            <!-- <hr> -->
                            <h3>Standard/Moderate Cancellation Policy</h3>
                            <!-- <hr> -->
                            <p>
                                If the Guest cancels the booking anywhere before 14 days before check-in date, they are
                                entitled
                                to a 100% refund.
                            </p>
                            <!-- <hr> -->
                            <p>
                                Any cancellations made after 14 days to check in date, there would be no refund.
                            </p>
                            <!-- <hr> -->
                            <p>
                                If the Guest has made a partial payment, he/she is entitled to no refund whatsoever.
                            </p>
                            <!-- <hr> -->
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
                                    <path
                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z">
                                    </path>
                                </svg> Standard check-in time is 12:00 PM and check-out time is 10:00 AM.
                            </p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
                                    <path
                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z">
                                    </path>
                                </svg> GST is applicable on cancellation fee.
                            </p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
                                    <path
                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z">
                                    </path>
                                </svg> All refunds issued exempt accommodation fee and cleaning fee.
                            </p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
                                    <path
                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z">
                                    </path>
                                </svg> In case of early check-ins and extended departures, an additional fee may be charged
                                depending on Rio Colina’s discretion and availability of the home.
                            </p>
                            <!-- <hr> -->
                            <h3>Terms & Conditions</h3>
                            <p>
                                In case of any refunds that Rio Colina owes Guest(s), the processing shall be completed
                                within
                                5-7 working days. A nominal cancellation fee of 5% shall be levied for refunds processed by
                                Rio
                                Colina. This is primarily levied to cover payment gateway charges and minimal processing
                                charges.
                            </p>
                            <!-- <hr> -->
                            <p>
                                GST at applicable rates will be charged on this cancellation fee.
                            </p>
                            <!-- <hr> -->
                            <p>
                                A reservation is considered officially 'canceled' when the guest clicks the cancellation
                                button
                                on the cancellation confirmation page, which they can find in Dashboard ➞ Your Trips ➞
                                Cancel,
                                or he/she calls our Reservations Agent and confirms the same.
                            </p>
                            <!-- <hr> -->
                            <p>
                                If the guest checks-in and decides to leave early, any refund of the accommodation fee and
                                cleaning fee will be decided and settled as considered appropriate by Rio Colina.
                            </p>
                            <!-- <hr> -->
                            <h3>Refund for Extra Charges</h3>
                            <p>
                                In case of any change in group size, the extra bedding charges collected can be refunded if
                                intimated to Rio Colina minimum 2 days prior to check-in. As with all refunds a 5%
                                processing
                                fee shall be levied for processing the refund. No refund for extra bedding charges shall be
                                processed if informed within 2 days prior check-in or after check-in.
                            </p>
                            <!-- <hr> -->
                            <h3>Cancellation by Rio Colina</h3>
                            <p>
                                Rio Colina reserves the right to cancel a booking. Rio Colina will offer the guest one of
                                the
                                following options.
                            </p>
                            <!-- <hr> -->
                            <p>
                                Book an alternative comparable available Home, if available
                            </p>
                            <!-- <hr> -->
                            <p>
                                Rio Colina shall offer Travel Credit (validity of 6 months from date of issuance) of the
                                full
                                amount paid + 10% value of the existing booking that may be redeemed while booking a new
                                home.
                            </p>
                            <!-- <hr> -->
                            <p>
                                A full refund for the total amount paid. The refund shall be processed within 5-7 working
                                days
                                from the date of cancellation.
                            </p>
                            <!-- <hr> -->
                            <h3>Reschedule Policy</h3>
                            <p>
                                Bookings can be rescheduled only at the same home.
                            </p>
                            <!-- <hr> -->
                            <p>
                                If the tariff of the new dates is higher than the tariffs of the original booking, the
                                difference is payable to Rio Colina to confirm the new dates.
                            </p>
                            <!-- <hr> -->
                            <p>
                                Rio Colina allows reschedulement only up to 2 days prior to check-in.
                            </p>
                            <!-- <hr> -->
                            <p>
                                Rio Colina charges no reschedulement charge if the booking is rescheduled within 12 hours of
                                payment (Not valid on stays where check-in is within 7 days of booking).
                            </p>
                            <!-- <hr> -->
                            <p>
                                Rio Colina charges 15% reschedulement charge + taxes if he/she reschedules the confirmed
                                booking
                                upto 7 days before the Date of Check-In (before 1 PM).
                            </p>
                            <!-- <hr> -->
                            <p>
                                Rio Colina charges 50% reschedulement charge + taxes if he/she reschedules the confirmed
                                booking
                                upto 2 days before the Date of Check-In (before 1 PM).
                            </p>
                            <!-- <hr> -->
                            <p>
                                Reschedulement charge is applicable, every time a booking is rescheduled.
                            </p>
                            <!-- <hr> -->
                            <h3>Processing Refunds</h3>
                            <p>
                                In case of any refunds that Rio Colina owes Guest(s), the processing shall be completed
                                within
                                5-7 working days.
                            </p>
                            <!-- <hr> -->
                            <p>
                                A nominal cancellation fee of 5% shall be levied for processing refunds by Rio Colina. This
                                is
                                levied to cover payment gateway charges along with a minimal processing charge.
                            </p>


                            <!-- FOOTER -->
                            <div class="container terms_footer">
                                <h3></h3>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
            <!-- /.container -->
        </main>
    </section>
    <Footer />
</template>

<script>
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';

export default {
    name: 'RefundAndReturnPolicy',
    components: { Header, Footer },
    mounted() {
        let recaptchaScript2 = document.createElement("script");
        recaptchaScript2.setAttribute(
            "src",
            "./js/slick/slick.js"
        );
        document.head.appendChild(recaptchaScript2);
        let recaptchaScript3 = document.createElement("script");
        recaptchaScript3.setAttribute(
            "src",
            "./js/slick/slick.min.js"
        );
        document.head.appendChild(recaptchaScript3);
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
</script>

<style scoped>
.terms-container {
    font-family: 'Open Sans', sans-serif;
    color: #262626;
}

.terms-title h1 {
    font-size: 25px;
    font-family: "Itim";
    text-align: center;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 20px;
}

.terms-body h4 {
    color: #EB573D;
    font-size: 14px;
    line-height: 25px;
}

.terms-body h3 {
    font-family: "Itim";
    color: #262626;
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
    font-size: 19px;
}

.terms-body p {
    font-size: 19px;
    line-height: 25px;
    margin-bottom: 20px !important;
}

.terms-body a {
    color: #EB573D;
}

.terms-body a:hover {
    color: #EB573D;
    text-decoration: none;
}

.terms_footer h3 {
    margin-top: 60px;
    margin-bottom: 125px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.5;
    color: #262626;
}

.terms_footer h3 a:link {
    color: #EB573D;
    text-decoration: none;
}

img {
    width: 100%;
    height: 100%;
}

hr {
    clear: both;
    visibility: hidden;
}

.linkColor {
    color: #0000fc !important;
}

.terms-body {
    line-height: 32px;
}</style>